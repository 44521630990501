<template>
    <Component
        :is="contentfulContentBlock"
        v-if="errorContent"
        :contentRows="errorContent.rows"
        :contentTitle="errorContent.title"
    />
</template>

<script>
import { mapState, mapActions } from 'vuex';

import analyticsMixin from '~coreModules/analytics/js/analytics';
import { CONTENT_SLUGS } from '~coreModules/contentful/js/contentful-constants';
import { FETCH_ERROR_CONTENT } from '~coreModules/core/js/store';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Error404',
    mixins: [analyticsMixin],
    props: {
        contentfulContentBlock: {
            type: [Function, Object],
            required: true,
        },
    },
    computed: {
        ...mapState([
            'routingError',
            'errorContent',
        ]),
    },
    async created() {
        this.$logger.debugError('Rendering 404 view due to error: ', this.routingError);

        await this.fetchErrorContent({ slug: CONTENT_SLUGS.NOT_FOUND_PAGE });
    },
    methods: {
        ...mapActions({
            fetchErrorContent: FETCH_ERROR_CONTENT,
        }),
    },
};
</script>
